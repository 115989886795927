import React from "react";
import { Text, Grid } from "theme-ui";
import Layout from "../components/layout";
import ColumnContainer from "../components/layout/column-container";
import ThemeableHeader from "../components/layout/themeable-header";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import Seo from "../components/seo";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <ThemeableHeader color="coral" title={"Not Found"} />
    <Grid as="section" columns={1} gap={[4, 5, 6]} sx={{ my: [5, 6] }}>
      <ColumnContainer Textcolor="coral" ner>
        <Text as="h2" variant="title">
          Apologies, we cannot find that page.
        </Text>
      </ColumnContainer>
      <NewsletterSignup />
    </Grid>
  </Layout>
);

export default IndexPage;
